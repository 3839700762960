$primary: #1898DA
$white: #fff 
$grey: #4f4f4f 
$grey-light: rgba(175, 175, 175, .4)
$success-clean: #28DA5F
$success: #52C17C
$dark: #575757
$danger: #F00606
$danger-light: rgba(240, 6, 6, .5)
$primary-light: rgba($primary, .75)
$warning: orange

@import ~bulma

*:focus
    outline: none

body 
    line-height: 1.35

::-webkit-scrollbar
    max-width: 2px
    max-height: 2px
::-webkit-scrollbar-track
    background: rgba(0,0,0,0.1)
::-webkit-scrollbar-thumb 
    background: rgba(0,0,0,.2)
    
.cart-bottom
    position: relative
    top: -3px

.no-warap-white-space
    white-space: nowrap !important
.none 
    display: none

.select select
    min-width: 150px
.select:not(.is-multiple):not(.is-loading)::after
    border-color: $grey

.no-arrow.select:not(.is-multiple):not(.is-loading)::after
    border-color: rgba(0,0,0,0) !important
.select.no-arrow select:not([multiple])
    padding-right: 1em !important
    

@mixin center-justify 
    display: flex !important
    justify-content: center
    flex-wrap: wrap

@mixin center-align
    display: flex !important
    align-items: center
    flex-wrap: wrap

.center-align
    @include center-align    

.flex-column 
    display: flex !important
    flex-wrap: wrap
    flex-direction: column

@mixin _center 
    display: flex !important
    align-items: center
    justify-content: center
    flex-wrap: wrap

@mixin center-no-wrap
    display: flex
    align-items: center
    justify-content: center
    
@mixin evenly 
    display: flex !important
    align-items: center
    justify-content: space-evenly

.flex-start
    display: flex
    align-items: center
    justify-content: flex-start

@mixin between
    display: flex !important
    align-items: center
    justify-content: space-between

.between
    @include between
    

.space-evenly 
    @include evenly

.center-no-wrap
    @include center-no-wrap

.space-between 
    display: flex !important
    justify-content: space-between
    flex-wrap: wrap

._center 
    @include _center

.top-m6px
    margin-top: -15px

.mt-m1
    margin-top: -.5rem

.max-90
    max-width: 90% !important
.w-100
    width: 100%
.h-100
    height: 100%
.h-80
    height: 80%
.flex-end
    display: flex 
    justify-content: flex-end

body 
    display: flex 
    justify-content: center

#root
    width: 100%
    @media screen and ( min-width: 800px )  
        @include _center
        flex: 0 0 100%
        max-width: 800px !important
        .App 
            display: block
            width: 100%
            transition: ease-in 1s

.translate-safari
    transform: translate3d(0,0,0)

.block-white
    padding: 3.5rem 1rem
    background: #fdfdfd
    border-radius: 7px

.container 
    margin-left: 1rem
    margin-right: 1rem

h5, h6, .mini-title
    display: block 
    margin-top: 10px
    font-size: .8em
    color: $grey
h5 
    text-transform: uppercase

.word-break
    word-break: break-all

.blanc-35
    width: 35px !important
.blanc-50
    width: 50px

.bg-transparent
    background: transparent !important

.buttons.bottom
    position: fixed 
    bottom: 10%
    left: 50%
    margin-left: -6rem
    .button
        width: 6rem
        &.is-outlined
            &:hover 
                background: $white
                color: $primary

.h-50px
    min-height: 50px

.pointer 
    cursor: pointer
.buttons.bottom-only
    position: fixed 
    bottom: 10%
    left: 50%
    margin-left: -3rem

.scroll-x
    overflow-y: hidden !important

.allow-scroll 
    .scroll-x
        // transform: scaleX(1.01)
        overflow-x: scroll !important
        padding-bottom: 5px
        *
            max-width: 500% !important
.disallow-scroll
    .scroll-x
        overflow-x: hidden !important
        padding-bottom: 5px

.pre-wrap
    white-space: pre-wrap
    text-transform: lowercase
    strong
        padding-left: 4px
        font-weight: 500
        color: $grey 
        text-transform: capitalize

.lower
    text-transform: lowercase !important

.text-center 
    text-align: center
.text-left
    text-align: left
.text-grey 
    color: $grey !important
.text-primary
    color: $primary !important
.text-dark
    color: $dark !important
.text-danger
    color: $danger !important
.text-bold
    font-weight: bold 
.weight-200
    font-weight: 200 
.mb-1
    margin-bottom: .75rem

.mb-m5px
    margin-bottom: -5px !important
.mr-m20px
    margin-right: -20px !important
.mr-m10px
    margin-right: -10px !important

.mr-1
    margin-right: .75rem
.size-normal
    font-size: 1em !important
.uppercase
    text-transform: uppercase
.size-normal-small
    font-size: .9em
.size-small
    font-size: .82em
.size-smaller
    font-size: .7em !important
.size-big
    font-size: 1.75em !important
.size-biggest
    font-size: 5em

.block 
    display: block
.size-bigger 
    font-size: 2em

.flex 
    display: flex
    flex-wrap: nowrap
    margin-right: -15px

.flex-1
    flex: 0 0 8.3%
    width: 8.3%
.flex-2
    flex: 0 0 16.6%
    width: 16.6%
.flex-3
    flex: 0 0 25%
    width: 25%

.flex-4
    flex: 0 0 33%
    width: 33%

.flex-5
    flex: 0 0 42%
    width: 42%

.flex-6
    flex: 0 0 50%
    width: 50%

.flex-7
    flex: 0 0 58.3%
    width: 58.3%

.flex-8
    flex: 0 0 66.6%
    width: 66.6%

.flex-9
    flex: 0 0 75%
    width: 75%
.flex-10
    flex: 0 0 83.5% !important
    width: 83.5% !important

.flex-11
    flex: 0 0 92%
    width: 92%
.flex-12
    flex: 0 0 100%
    width: 100%

.quantity
    background: $primary
    width: 18px
    height: 18px
    color: $white 
    border-radius: 50%
    font-size: .9rem

.clean-button 
    border: none
    background: #fff 
    cursor: pointer

.border-bottom
    border-bottom: 1px solid $grey-light
    padding-bottom: 1rem
    margin-bottom: 1.5rem
    &.dark
        border-color: $grey

.total-right
    @media screen and ( min-width: 520px ) 
        float: right
        margin-right: .75rem

.border-top
    border-top: 1px solid $grey-light
    padding-top: 1rem
    margin-top: 1.5rem
    

.border-bottom-danger
    border-bottom: 1px solid $danger-light
    padding-bottom: 1rem
    margin-bottom: 1.5rem

hr 
    background: $grey-light
    height: 1px

#add-to-cart, .add-to-cart
    transition: ease .7s

.none-add-cart
    transform: scale(0)
    height: 0
    width: 0

.after-add-to-cart
    &:not(.none)
        display: block
        height: 95px

.produto-
    font-size: .8rem
    position: relative
    top: -5px

div
    .underline-title 
        border-bottom: 2px solid $primary
        padding-bottom: 5px
    &.underline-full
        .underline-title
            border-bottom: none 
            padding-bottom: 0
            &:after 
                content: ""
                display: block 
                position: relative  
                // left: -1rem
                border-bottom: 2px solid $primary
                padding-bottom: 5px

h3 
    padding-bottom: 7px

.no-pad
    padding: 0 !important    

.mt-0
    margin-top: 3px
.pb-0
    padding-bottom: 0px !important
.mt-10px
    margin-top: 11px

.none
    display: none

#react-confirm-alert
    .react-confirm-alert-overlay
        background: rgba(0,0,0,.6)
    .react-confirm-alert
        .react-confirm-alert-body
            color: $grey
            text-align: center
            h1 
                color: $grey
                font-weight: bold
                margin-bottom: 25px
                text-align: center
        .react-confirm-alert-button-group 
            @include _center
            &> button
                padding: 10px 25px
                background: #fff
                text-transform: uppercase
                // color: $white
                font-weight: bold
                &:first-child
                    // border: 1px solid $primary
                    // background: $white
                    text-transform: uppercase
                    // color: $primary
                    font-weight: bold
button.btn, a.btn, label.btn
    background: $primary
    color: $white
    border: none
    padding: 10px 25px
    border-radius: 5px
    cursor: pointer
    &.medium
        padding: 7px 15px


input[type=text]
    color: $grey
    border-top: 0
    border-left: 0
    border-right: 0
    box-shadow: none
    &:focus
        outline: none 
        box-shadow: none 
        border: none
        border-bottom: 1px solid rgba(54,54,54,.2)

.is-inline span
    display: inline !important
    padding-left: 0
    position: relative
    &:first-child
        right: -10px
    &:last-child
        left: -5px

.finish, .phone, div:not(.App) .loading-screen
    width: 100vw
    height: 100vh
    @include _center
    flex-direction: column
    @media screen and ( min-width: 800 )
        height: 50vh

.loading-screen
    margin-top: -20px
    max-height: 92vh !important

div.App .loading-screen
    width: 100%
    height: 150px
    @include _center
    flex-direction: column

.carregando-endereco
    position: fixed 
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0,0,0,.2)
    @include _center
    flex-direction: column

.trans-6
    transition: ease .6s

.phone
    height: 79vh
    @media screen and ( min-width: 800 )
        height: 30vh !important

.make-flex-end label
    justify-content: flex-end !important

._content
    position: relative
    z-index: 1

.cart
    padding: 0 !important
    margin: 0 !important
    .products-cart
        background: $danger
        box-shadow: .7px 0 1px rgba(0,0,0,.65)
        display: block 
        color: $white
        border-radius: 50%
        position: relative
        margin-top: -60px
        margin-left: 15px
        width: 15px
        height: 15px 
        &.tabs
             margin-top: -25px
             width: 13px
             height: 13px
             margin-left: -10px
             line-height: 5px
             font-size: .75rem
        @media screen and ( min-width: 400px )
            margin-top: -30px
            margin-left: -15px

.first-capital
    &::first-letter
        display: inline-block
        text-transform: uppercase !important

.modal-bebidas
    position: fixed
    left: 5%
    right: 5%
    top: 25%
    bottom: 25%
    background: $white
    z-index: 10
    border-radius: 10px

@keyframes rotate
    0%
        transform: rotate(0deg)
    99%
        transform: rotate(360deg)

.loading
    width: 60px
    height: 60px
    border-top: 3.5px solid 
    border-bototm: 3.5px solid 
    border-right: 3.5px solid 
    border-radius: 50%
    animation: rotate .8s infinite
    animation-timing-function: linear

.center-grid
    display: grid 
    align-itens: center 
    justify-content: center


@keyframes chacoalhar
    0%
        transform: rotate( 6.5deg )
    50%
        transform: rotate( -6.5deg )
        

.tremer
    animation: chacoalhar .2s infinite
    box-shadow: 5px 5px 6px rgba(0, 0, 0, .5)

.half-margin
    margin-bottom: 30vh

@import components/header/header
@import components/category/category
@import components/forms/forms
@import components/checkout/checkout

.dialog-alert 
    position: fixed
    top: 7%
    left: 2%
    right: 2%
    z-index: 10
    border-radius: 5px
    margin-top: -200px
    transition: ease-in-out .7s

.dialog-alert.active
    margin-top: 0

.dialog-alert .is-danger
    background-color: rgb(255, 130, 164) 
    border: 2px solid #f14668
.dialog-alert .is-success
    background-color: rgb(102, 213, 144)
    border: 2px solid $success
.dialog-alert > .message
    padding: 1rem

.dialog-alert p 
    margin: 0

.country-flag
    width: 45px

.grid_lojas
    display: grid 
    grid-template-columns: 50% 50% 
    &>div
        padding: 5px
        img 
           width: 100px