button.checkout, a.checkout, label.checkout
    position: fixed
    bottom: 5%
    left: 50%
    margin-left: -60px 

.checkout 
    label 
        font-weight: bold
    .btn 
        padding: 8px 20px
