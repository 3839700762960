.category-grid
    .bg 
        background-color: #D6D6D6
    .name
        font-size: .75rem
        &:not(.active)
            opacity: .7
    &:not(.is-column, .is-department)
        display: grid 
        grid-template-columns: repeat(4, 25%)
        -webkit-grid-template-columns: 25% 25% 25% 25%
    &.is-column
        display: flex
        flex-wrap: wrap
        a
            flex: 0 0 100%
            width: 100%
            .category-block
                display: flex 
                align-items: center
                justify-content: flex-start
                width: 100%
                flex: 0 0 100%
                background-color: #ffffff
                box-shadow: 1.5px 2px 4px rgba(0,0,0,.1)
                img
                    width: 55px
                    height: 55px
                h6 
                    flex: 0 0 65%
                    width: 65%
                    font-weight: 600 !important
                    padding-left: 25px
                    font-size: .85rem
                    text-align: left !important
    &:not(.is-department)
        margin-bottom: 1rem 
    .category-block
        margin-left: .8rem
        margin-top: 1rem 
        &:nth-child(3n+1), &:first-child
            margin-left: .5rem
            margin-right: .25rem  
        .square
            @include _center
            height: calc( 100vw - 75vw - 1rem )
            color: $white
            border-radius: 10%
            @media screen and ( min-width: 800px )
                height: 224px
            span
                font-size: 2em
            img 
                width: 100%
                height: 100%
                border-radius: 10%
                @include _center
    &.is-department
        align-items: center
        flex-wrap: nowrap !important
        flex: 0 0 25%  
        .category-block
            @include _center
            flex-direction: column
        .circle
            @include _center
            border-radius: 50%
            width: 72.5px
            height: 72.5px
            color: $white
            @media screen and ( min-width: 800px )
                height: 224px
                width: 224px
            &:not(.active)
                opacity: .7
            span
                font-size: 2em
            img 
                width: 100%
                height: 100%
                border-radius: 10%
                @include _center
            