.min-height-80
    min-height: 80px
.header
    &.background-cover
        background-size: cover
    &.bg-bigger
        background-size: 55%
        @media screen and ( min-width: 990px )
            background-size: 40%
    @include center-justify
    min-height: 35vh
    position: relative
    z-index: 1
    @media screen and ( max-width: 320px )
        min-height: 250px
    &.simple 
        min-height: 10vh
        // min-height: 100px
        @media screen and ( max-width: 320px )
            height: auto
    padding: 1%
    flex-direction: column
    .header-line
        @include center-align
        justify-content: space-between
        flex-wrap: wrap !important

    .material-icons, .header-left > .info
        color: $white
    .material-icons
        font-size: 35px
    .info 
        @include center-no-wrap
        color: $white
        border: 2px solid $white
        font-size: 1.3em
        border-radius: 50%
        width: 30px
        height: 30px
        margin-left: 5px
        cursor: pointer
        &.is-9801
            border: 2px solid $dark
        span 
            position: relative
            top: 1.5px 
    .brand
        @include _center
        flex-wrap: wrap !important
        max-width: 100% !important
        .app-logo
            border-radius: 50%
            border: 3px solid $white
            max-width: 86px
            max-height: 86px
            &.full-img
                border: none
                max-width: 130px 
                max-height: 130px
                width: 130px
                height: 130px
        .description 
            margin-top: 10px
            padding-bottom: 10px
            flex: 0 0 100%
            text-align: center
            color: $white
            .title 
                text-transform: uppercase
                font-weight: 300
            .deliver 
                display: block
                font-size: .8em
                margin-top: 7px
                // font-weight: 400
.header-bottom
    position: relative
    top: -7px
    height: 15px
    background: $white
    width: 100%
    border-radius: 75px
    z-index: 1

.header-shadow, .header-shadow *
    text-shadow: 1px 1px .5px #000000
    .deliver
        text-shadow: 2px 2px .5px #000000, -1px -1px .5px #000000
        background-color: rgba(0, 0, 0, 0.08) 