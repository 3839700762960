.forms
    position: relative
    .subtitle
        color: $grey
        font-size: 1em
        // margin-top: 5px
        margin-bottom: 1px
    .price
        font-size: .85em
        color: $success
    button.open-modal
        width: 100%
        height: 100%
    .form-group-modal
        overflow: visible
    .form-group-modal, .form-group-modal-compressed
        position: fixed
        transition: ease .25s
        overflow-y: scroll
        padding-bottom: 50px
        &.none
            visibility: hidden 
            transform: scale(0)
        &.show
            visibility: visible
            bottom: 1.5rem
            top: 1.5rem
            left: 2rem
            right: 2rem
            background: #fff
            z-index: 9
            border-radius: 10px 
            transform: scale(1)
            @media screen and ( min-width: 550px )
                width: 75% !important
                left: 12.5%
            @media screen and ( min-width: 800px )
                width: 50% !important
                left: 25% 
    .form-group-modal-compressed
        overflow: auto 
        &.show
            &:not(.small)
                bottom: 25%
                top: 25%
            &.small
                bottom: 28%
                top: 28%
            left: 1rem
            right: 1rem
            @media screen and ( min-width: 534px )
                left: 50%
                margin-left: -30%
                right: 50%
                margin-right: -30%
            @media screen and ( min-width: 800px )
                left: 50%
                margin-left: -25%
                
        header 
            padding-top: .5rem
            padding-bottom: .5rem
            
        button.button 
            background: #fff 
            &.active:after
                content: ""
                display: block
                width: 12px
                height: 12px
                border-radius: 50%
                position: relative

                background: $grey
            &.radio
                width: 18px
                height: 18px
                border: 1px solid $grey
                border-radius: 50%
                padding: 2px


    .close-modal-bg
        position: fixed
        &.none 
            display: none
        &.show 
            display: block
            top: 0
            bottom: 0
            left: 0
            right: 0
            background: rgba(0,0,0,.5)
            z-index: 8
    .clean-close-modal .close-modal-bg
        &.show
            background: rgba(0,0,0,0) !important
    .hide
        opacity: 0
        width: 0
    .border-rounded
        border: 1px solid $primary
        width: 24px
        height: 24px
        border-radius: 50%

.show-submit 
    &:not(.none)
        @include evenly
        position: fixed 
        bottom: 0
        left: 0
        right: 0
        height: 95px 
        width: 100%
        background: $white
        box-shadow: -25px 0 25px rgba(0,0,0,.25), -40px 5px 35px rgba(0,0,0,.15)
        p, button
            font-size: 1rem
            transition: ease .6s

.snack-image 
    width: 55px
    height: 55px
    border: none
    border-radius: 50%
    background-color: $grey-light
    overflow: hidden
img.snack-image
    background: transparent !important
.snack-big
    position: fixed
    left: 50%
    top: 50%
    transition: ease .5s
    @media screen and ( min-width: 500px )        
        max-width: 450px !important
        max-height: 450px !important
        margin-left: -20% !important
        margin-top: -20% !important
    &.expand
        position: fixed 
        z-index: 10
        border-radius: 0
        border: 3px solid $white
    &.material-icons-outlined
        font-size: 8em
        left: 75% !important
        top: 75% !important
.expand-bg
    &.active
        position: fixed 
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: rgba(0,0,0,.2)
        z-index: 9
    &.none
        display: none
.observation
    width: 100%
    border: 1px solid $grey-light !important
    padding: .7rem 10px

.z-if-none-out
    &:not(.none)
        z-index: 10
        &.z-max
            z-index: 11
        &.go-to-checkout
            z-index: 2

.checkout .z-if-none-out
    z-index: 7

.button.has-background-grey
    background-color: rgba($grey, .9) !important

input[type=radio]
    visibility: hidden
    opacity: 0
    width: 0
    height: 0
    &:checked+span:after, &.checked+span:after
        // &:after 
        display: block 
        visibility: visible
        content: ""
        opacity: 1              
        width: 9px
        height: 9px
        border-radius: 50%
        background: $grey
        // border: .7px solid #fff
    &+span
        display: block               
        width: 15px
        height: 15px
        border: 1px solid $grey
        border-radius: 50%
        padding: 2px
input[type=checkbox]
    visibility: hidden
    opacity: 0
    width: 0
    height: 0
    &:checked+span:after
        // &:after 
        display: block 
        visibility: visible
        content: ""
        opacity: 1              
        width: 9px
        height: 9px
        background: $success-clean
    &+span
        display: block               
        width: 15px
        height: 15px
        border: 1px solid $grey
        border-radius: 1.5px
        padding: 2px

.number-input-container
    @media screen and (max-width: 400px)
        justify-content: center 
        button[type=button]
            width: 35%
            margin: 0
            &:focus
                outline: none
                box-shadow: none
                boder: none

.modal-bottom
    padding-bottom: 10vh !important

.invalid
    border: 1px solid rgba($danger, 0.3)
    &:focus, &:hover
        outline: 1px solid rgba($danger, 0.3)
        border: 1px solid rgba($danger, 0.3) 
        border-bottom: 1px solid rgba($danger, 0.3) !important
    // border-radius: 2px


.warning
    border: 1px solid rgba($warning, 0.3)
    &:focus, &:hover
        outline: 1px solid rgba($warning, 0.3)
        border: 1px solid rgba($warning, 0.3) 
        border-bottom: 1px solid rgba($warning, 0.3) !important
    // border-radius: 2px

input[type=text].input 
    background: rgba($white, .75)

// .select select
//     min-width: 100px 

input:not([type=number]), select
    font-size: .87rem !important

.troco
    background-color: rgba(255,255,255,.0)