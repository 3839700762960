.white-box 
    background-color: #fff
    padding: 10px
    border: 1px solid rgba(0, 0, 0, .4)
    border-radius: 20px

.icone-ativo
    color: rgb(24, 152, 218)
    width: 35px
    height: 35px

.status-lista
    li 
        display: flex
        align-content: center 
        span.material-icons
            margin-right: 20px
            display: block
            border-radius: 50%
            background: rgba(0, 0, 0, .2)
            padding: 5px
            margin-bottom: 5px
